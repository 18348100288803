// import { loadScript, loadStyle } from "lib/scripts";
// import shadowImg from "./ui/layout/img/modal_shadow.png";
//
import("./runnerVendor1");
import("./runner");
//
// loadScript("https://vk.com/js/api/openapi.js?169")
//
// function preloadImage (url) {
//   try {
//     var _img = new Image();
//     _img.src = url;
//   } catch (e) { }
// }
//
// preloadImage(shadowImg);


// import './runner'